import React from 'react'
import { Modal, Heading, Form, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import Queries from '../../../tools/Query'

/**
 * Fenêtre de bulk modification pour modifier plusieurs champs :
 * 	- commentaire
 * 	- niveau
 * 	- classe
 */
export default class StandardEdit extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.name = 'standardEdit'
		this.state = {
			loading: false,
			Commentaire: '',
			inputText: false,
			otherInput: '',
			submitDisable: true,
		}
		this.roles = {}
	}

	/**
	 * Récupère les utilisateurs filtrés.
	 * @returns {[]}
	 */
	getSelectedAfterFilter = () => {
		const users = []
		this.context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				users.push(node.data['ID utilisateur'])
			}
		})
		return users
	}

	/**
	 * Met à jour les utilisateurs.
	 * @param formEvent
	 */
	onSubmit = formEvent => {
		formEvent.preventDefault()

		const { bulkAction, onClose } = this.props
		const { addToast, refresh, translate } = this.context
		const field = this.state.inputText ? this.state.otherInput : this.state[bulkAction]

		const selected = this.getSelectedAfterFilter()

		this.setState({ loading: true })

		Queries.updateBulk(this.context, selected, bulkAction, field, 'std')
			.then(res => {
				addToast(translate('modals.generic.bulk_success', { action: bulkAction, users: selected.length }), { appearance: 'success' })
				refresh()
				onClose(this.name)
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				onClose(this.name)
			})
	}

	/**
	 * Gère la saisie utilisateur pour un champ texte.
	 * @param event
	 */
	handleInput = event => {
		const name = event.target.name
		const value = event.target.value

		value === 'Autre'
			? this.setState({
					[name]: '',
					inputText: true,
					submitDisable: true,
			  })
			: this.setState({
					[name]: value,
					inputText: false,
					submitDisable: value === 'null',
			  })
	}

	/**
	 * Gère la saisie pour un autre champs.
	 * @param keyboardEvent
	 */
	handleOtherInput = keyboardEvent => {
		const value = keyboardEvent.target.value.trim()
		this.setState({
			otherInput: value,
			submitDisable: value === '',
		})
	}

	/**
	 * Ajoute une classe ou un niveau dans la liste
	 * @param bulkAction
	 * @returns {unknown[]}
	 */
	createOptions = bulkAction => {
		if (bulkAction === 'Profil') {
			return (
				<>
					<option value="Eleve">Eleve</option>
					<option value="Enseignant">Enseignant</option>
				</>
			)
		}
		return Object.keys(this.searchThroughTable(new RegExp(bulkAction)))
			.sort()
			.map((value, key) => (
				<option key={key} value={value}>
					{value}
				</option>
			))
	}

	/**
	 * Recherche des valeurs dans la grille
	 * @param value
	 * @returns {{}}
	 */
	searchThroughTable = value => {
		let res = {}

		this.context.grid.forEachNode(node => {
			for (const j in node.data) {
				if (value.test(j) && node.data[j] !== null) {
					res = { ...res, [node.data[j]]: true }
				}
			}
		})

		return res
	}

	render() {
		const { bulkAction } = this.props
		const { translate } = this.context

		return (
			<Modal className={!this.context.isLightTheme ? 'is-dark' : ''} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<form onSubmit={this.onSubmit}>
							<header className="is-flex" style={{ justifyContent: 'space-between' }}>
								<div>
									<Heading size={5}>{translate('modals.generic.mass_update')}</Heading>
									<Heading size={6} subtitle>
										{bulkAction}
									</Heading>
								</div>
								<Button color={'light'} type={'button'} onClick={() => this.props.onClose(this.name)}>
									<Icon icon={'fal fa-times fa-2x'} />
								</Button>
							</header>
							<Hr />
							<Heading subtitle marginless size={5} textColor={'primary'}>
								{this.getSelectedAfterFilter().length} {translate('global.users')}
							</Heading>
							<Form.Field>
								<Form.Control className={'is-expanded'}>
									{['Niveau', 'Classe', 'Profil'].includes(bulkAction) ? (
										<div className="select is-fullwidth">
											<select name={bulkAction} onChange={this.handleInput}>
												<option value="null">&lt;{translate('modals.generic.select_value')}&gt;</option>
												{bulkAction !== 'Profil' && <option value="Autre">&lt;{translate('global.add')}&gt;</option>}
												{bulkAction === 'Classe' && <option value="">&lt;{translate('global.remove')}&gt;</option>}
												<option disabled>━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━</option>
												{this.createOptions(bulkAction)}
											</select>
										</div>
									) : (
										<Form.Input value={this.state.Commentaire} type={'text'} id={'commentaire'} name={'Commentaire'} placeholder={translate('global.comment')} onChange={this.handleInput} autoFocus />
									)}
								</Form.Control>
							</Form.Field>
							{this.state.inputText && (
								<Form.Field>
									<Form.Control>
										<Form.Input value={this.state.otherInput} type={'text'} name={'otherInput'} id={'otherInput'} placeholder={bulkAction} onChange={this.handleOtherInput} autoFocus />
									</Form.Control>
								</Form.Field>
							)}
							<br />
							<div className="is-flex" style={{ justifyContent: 'space-between' }}>
								<Button type={'reset'} color={'danger'} onClick={() => this.props.onClose(this.name)}>
									{translate('global.cancel')}
								</Button>
								<Button loading={this.state.loading} type={'submit'} color={'primary'} disabled={this.state.submitDisable}>
									{translate('global.confirm')}
								</Button>
							</div>
						</form>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
