import React, { useCallback, useContext, useEffect, useState } from 'react'
import Query from '../../../tools/Query'
import GlobalContext from '../../../contexts/GlobalContext'
import { Button, Content, Form, Heading } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalUtils from '../../utils/GlobalUtils'
import _ from 'lodash'

/**
 * Paramètrage de tous les champs de regex, conversion, et caractères spéciaux autorisés.
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalRegex() {
	const context = useContext(GlobalContext)
	const { addToast, translate, showModal } = context

	const [initialValue, setInitialValue] = useState(null)
	const [editing, setEditing] = useState(false)
	const [loading, setLoading] = useState(false)
	const [regexes, setRegexes] = useState(null)

	/**
	 * Récupère les regex au chargement.
	 * @type {(function(): void)|*}
	 */
	const fetchRegexes = useCallback(() => {
		Query.getGlobalSettings(context, 'regex')
			.then(regexes => {
				// On retire les backslashes des specials
				Object.values(regexes).forEach(field => {
					field.regexSpecial = field.regexSpecial?.replaceAll(`\\`, '')
				})

				setInitialValue(regexes)
				// On fait un cloneDeep ici pour éviter que ça pointe vers le même objet.
				// Le { ...regexes } ne marchait pas ??
				setRegexes(_.cloneDeep(regexes))
			})
			.catch(err => {
				setRegexes(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	/**
	 * S'il n'y a pas de regex, on va les chercher.
	 */
	useEffect(() => {
		if (!regexes) {
			fetchRegexes()
		}
	}, [fetchRegexes, regexes])

	/**
	 * Détecte si l'utilisateur a fait des changements.
	 * @returns {boolean}
	 */
	function hasMadeChanges() {
		return !_.isEqual(initialValue, regexes)
	}

	/**
	 * Support de saisie.
	 * @param name
	 * @param value
	 */
	function handleChange({ target: { name, value } }) {
		const [regex, field] = name.split('_')

		setRegexes(regexes => {
			regexes[regex][field] = value

			return { ...regexes }
		})
	}

	/**
	 * Met à jour les réglages en base en affichant un écran de confirmation avec les différences.
	 * @param formEvent
	 */
	function updateValues(formEvent) {
		formEvent.preventDefault()

		if (!editing) {
			return setEditing(true)
		}

		if (!hasMadeChanges()) {
			return setEditing(false)
		}

		const diff = {}

		for (const regex of Object.keys(initialValue)) {
			const currentDiff = GlobalUtils.diffBetweenObjects(regexes[regex], initialValue[regex])

			if (Object.values(currentDiff).length > 0) {
				diff[regex] = currentDiff
			}
		}

		showModal('confirmAction', true, {
			content: (
				<>
					<p>Êtes-vous sûr d'appliquer les modifications suivantes ?</p>
					<Content>
						<ul className="mt-2 is-size-7 has-text-grey-darker">
							{Object.entries(diff).map(([field, diff], idx) => (
								<li key={idx}>
									<span className="font-semibold">{field}</span> :<br />
									<ul>
										{Object.entries(diff).map(([key, value], idx) => (
											<li>
												<span>{key}</span>
												<br />
												<span className="font-mono" key={idx}>
													<span className="has-text-danger">&times; {initialValue[field][key]}</span>
													<br />
													<span className="has-text-success">&#8594; {value}</span>
												</span>
											</li>
										))}
									</ul>
								</li>
							))}
						</ul>
					</Content>
				</>
			),
			action: () => {
				setLoading(true)

				Query.updateGlobalSettings(context, 'regex', regexes)
					.then(() => {
						addToast(translate('settings.update_success'), { appearance: 'success' })
						setEditing(false)
						setLoading(false)
					})
					.catch(() => {
						addToast(translate('global.errors.occurred'), { appearance: 'error' })
						setLoading(false)
					})
			},
		})
	}

	return regexes ? (
		<>
			<form onSubmit={updateValues}>
				<header className="flex justify-between">
					<Heading size={5}>{translate('settings.regex')}</Heading>
					<Button color={editing ? 'success' : 'info'} size={'small'} type={'submit'} loading={loading}>
						<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
						<span>{editing ? translate('global.save') : translate('global.update')}</span>
					</Button>
				</header>
				<Content>
					<ul>
						{Object.entries(regexes).map(([_, { fieldName, regexSpecial, regexConversion, regexStructure }], idx) => (
							<li key={idx} className="py-4 border-t">
								<Heading size={6} subtitle>
									{fieldName}
								</Heading>
								<section className="flex">
									<Form.Field className="w-1/3 pr-4">
										<Form.Label size={'small'}>{translate('settings.struct')}</Form.Label>
										<Form.Control size={'small'}>
											<Form.Input name={`${fieldName}_regexStructure`} type={'text'} size={'small'} placeholder={'Structure'} value={regexStructure} disabled={!editing} onChange={handleChange} />
										</Form.Control>
									</Form.Field>
									<Form.Field className="w-1/3 pr-4">
										<Form.Label size={'small'}>{translate('settings.spe_carac')}</Form.Label>
										<Form.Control size={'small'}>
											<Form.Input name={`${fieldName}_regexSpecial`} type={'text'} size={'small'} placeholder={'Caractères spéciaux autorisés'} value={regexSpecial} disabled={!editing} onChange={handleChange} />
										</Form.Control>
									</Form.Field>
									<Form.Field className="w-1/3 pr-4">
										<Form.Label size={'small'}>Conversion</Form.Label>
										<Form.Control size={'small'}>
											<div className="select is-small is-fullwidth">
												<select name={`${fieldName}_regexConversion`} id={`${fieldName}_conversion`} defaultValue={regexConversion} disabled={!editing} onChange={handleChange}>
													<option value="MIN">MIN</option>
													<option value="MAJ">MAJ</option>
													<option value="FIRSTMAJ">FIRSTMAJ</option>
												</select>
											</div>
										</Form.Control>
									</Form.Field>
								</section>
							</li>
						))}
					</ul>
				</Content>
			</form>
		</>
	) : (
		<Skeleton />
	)
}

/**
 * Fake squelette pendant le chargement.
 * @returns {JSX.Element}
 * @constructor
 */
function Skeleton() {
	const fake = []

	for (let i = 0; i < 4; i++) {
		fake.push(
			<div className="border-t mx-4">
				<div className="w-32 h-6 bg-gray-200 animate-pulse rounded-lg my-3" />
				<div className="flex p-3 pb-5">
					<div className="w-1/3 pr-4">
						<div className="w-32 h-4 bg-gray-200 animate-pulse rounded-lg my-2" />
						<div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg" />
					</div>
					<div className="w-1/3 pr-4">
						<div className="w-32 h-4 bg-gray-200 animate-pulse rounded-lg my-2" />
						<div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg" />
					</div>
					<div className="w-1/3 pr-4">
						<div className="w-32 h-4 bg-gray-200 animate-pulse rounded-lg my-2" />
						<div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg" />
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="w-64 h-6 rounded-lg bg-gray-200 animate-pulse mb-4" />
			<div>{fake}</div>
		</>
	)
}

export default GlobalRegex
