import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Button, Form, Heading, Section, Tag} from 'react-bulma-components'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import {AgGridReact} from 'ag-grid-react'
import AG_GRID_LOCALE_FR from '../config/ag-grid-locale-fr'
import AG_GRID_LOCALE_EN from '../config/ag-grid-locale-en'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.min.css'
import MonitoringUtils from './utils/MonitoringUtils'
import Query from '../tools/Query'
import SchoolMonitoring, {resetSchoolWorkflow, SubStep} from './SchoolMonitoring'
import _ from 'lodash'
import Icon from './utils/Icon'

/**
 * Suivi global de chaque établissements, indiquant la situation pour chaque étape.
 * Il s'agit seulement de lecture, il n'y a pas de modification possible sur cette vue.
 * Cependant, un utilisateur peut cliquer sur la cellule avec le nom de l'établissement pour accéder à
 * la vue "Suivi" de cet établissement.
 * @return {JSX.Element}
 * @constructor
 */
function GlobalMonitoring() {
	/**
	 * Variable de contexte.
	 * @type {unknown}
	 */
	const context = useContext(GlobalContext)
	const { isLightTheme, translate, lang, addToast, removeGridFilter, workflowFilters, resetGridFilter, setGridFilter, showModal } = context

	const GLOBAL_MONITORING_VALUES = {
		DONE: '✔️',
		DOING: '🔨',
		NOT_NEEDED: '✖️',
		NONE: '',
	}

	const [selectedUAIs, setSelectedUAIs] = useState([])

	const [grid, setGrid] = useState(null)

	const [loading, setLoading] = useState(false)

	const [data, setData] = useState(null)

	const [columns, setColumns] = useState([])

	const [displayedSchool, setDisplayedSchool] = useState(null)

	useEffect(() => {
		function closeOnEscapeKey(event) {
			if (event.code === 'Escape') {
				setDisplayedSchool(null)
			}
		}

		window.addEventListener('keyup', closeOnEscapeKey)

		return () => {
			window.removeEventListener('keyup', closeOnEscapeKey)
		}
	}, [])

	useEffect(() => {
		if (!grid) {
			return
		}

		if (!_.isEqual(grid.api.getFilterModel(), workflowFilters)) {
			grid.api.setFilterModel(workflowFilters)
		}
	}, [grid, workflowFilters])

	/**
	 * Génère les colonnes de la grille avec des colonnes fixes (non variabilisé en BDD).
	 * @type {function(*=): function(): void}
	 */
	const generateColumnsDefs = useCallback(
		steps => () => {
			const headers = Object.entries(Object.values(steps)[0])
				.sort(([_a, a], [_b, b]) => a.orderInLevel - b.orderInLevel)
				.map(([header, _]) => ({
					field: header,
					headerName: header,
				}))

			const columnsDefs = [
				{
					field: 'updated_at',
					headerName: translate('global_monitoring.last_update'),
					filter: 'agDateColumnFilter',
					hide: true,
					comparator: (valueA, valueB) => valueA - valueB,
					valueFormatter: ({ value }) => (value ? value.toLocaleString() : 'Date invalide'),
				},
				{
					field: 'by',
					headerName: translate('global.by'),
					filter: 'agSetColumnFilter',
					hide: true,
				},
				{
					field: 'estab',
					headerName: translate('global.school'),
					filter: 'agTextColumnFilter',
					pinned: 'left',
					headerCheckboxSelectionFilteredOnly: true,
					headerCheckboxSelection: true,
					cellClass: 'ag-login',
					cellRenderer: 'agGroupCellRenderer',
					cellRendererParams: {
						checkbox: true,
					},
				},
				...headers,
			]

			setColumns(columnsDefs)
		},
		[translate]
	)

	const fetchData = useCallback(
		(gridColumns, grid) => () => {
			Query.getWorkflow(context)
				.then(({ steps, status }) => {
					setLoading(false)

					const formattedData = Object.entries(steps).map(([school, schoolStep]) => {
						const schoolData = {
							updated_at: null,
							by: null,
							estab: school,
						}

						for (const [step, { modifiedBy, modifiedDate, itemStatus, subLevels }] of Object.entries(schoolStep)) {
							const formattedSubSteps = subLevels ? Object.entries(subLevels).map(([subName, { modifiedDate, modifiedBy, itemStatus, eventId, itemId }]) => SubStep(itemId, eventId, MonitoringUtils.getStatusFromStatusList(itemStatus, status), subName, new Date(modifiedDate), modifiedBy)) : []

							if (new Date(modifiedDate) > schoolData.updated_at) {
								schoolData.updated_at = new Date(modifiedDate)
								schoolData.by = modifiedBy
							}

							/**
							 * S'il y a des sous-étapes, alors
							 */
							if (formattedSubSteps.length > 0) {
								/**
								 * Si TOUTES les sous étapes ont été faites, alors l'étape est complétée.
								 */
								if (formattedSubSteps.every(({ state }) => MonitoringUtils.isDone(state))) {
									schoolData[step] = GLOBAL_MONITORING_VALUES.DONE // MonitoringUtils.STATES.DONE

									/**
									 * Sinon, s'il y a au moins une sous-étape complétée, l'étape est en cours.
									 */
								} else if (formattedSubSteps.some(({ state }) => MonitoringUtils.isDone(state))) {
									schoolData[step] = GLOBAL_MONITORING_VALUES.DOING // MonitoringUtils.STATES.DOING
								} else {
									/**
									 * Il y a des sous-étpes mais aucune n'est commencé.
									 * @type {string}
									 */
									schoolData[step] = GLOBAL_MONITORING_VALUES.NONE
								}
								/**
								 * S'il n'y a pas de sous étapes, on récupère l'état en fonction de l'étape globale.
								 */
							} else {
								function getStatus() {
									const { statusId } = status.find(({ statusLabel }) => statusLabel === itemStatus) ?? 2

									switch (statusId) {
										case 1:
											return GLOBAL_MONITORING_VALUES.DONE
										case 5:
											return GLOBAL_MONITORING_VALUES.NOT_NEEDED
										case 2:
										default:
											return GLOBAL_MONITORING_VALUES.NONE
									}
								}

								schoolData[step] = getStatus()
							}
						}

						return schoolData
					})

					/*
						Mettre les etablissements dans l'ordre alpha
					 */
					formattedData.sort((etab1, etab2) => etab1.estab.localeCompare(etab2.estab))
					setData(formattedData)

					generateColumnsDefs(steps)()

					gridColumns.autoSizeAllColumns()

					if (workflowFilters) {
						grid.setFilterModel(workflowFilters)
					}
				})
				.catch(err => {
					addToast(translate('global.errors.occurred'), { appearance: 'error' })
				})
		},
		[context, generateColumnsDefs, workflowFilters, GLOBAL_MONITORING_VALUES.DONE, GLOBAL_MONITORING_VALUES.DOING, GLOBAL_MONITORING_VALUES.NONE, GLOBAL_MONITORING_VALUES.NOT_NEEDED, addToast, translate]
	)

	/**
	 * Définitions par défaut des colonnes.
	 * On fait en sorte qu'il est impossible de les modifier.
	 * On peut : les trier, modifier leur largeur et filtrer.
	 * On donne également une classe qui va changer la couleur d'arrière plan d'une étape selon son état.
	 * @type {{filter: string, floatingFilter: boolean, resizable: boolean, editable: boolean, cellClass: (function(*): (string)), sortable: boolean}}
	 */
	const defaultColumnsDefs = {
		editable: false,
		sortable: true,
		resizable: true,
		floatingFilter: true,
		filter: 'agSetColumnFilter',
		cellClass: params => {
			switch (params.value) {
				case GLOBAL_MONITORING_VALUES.DONE:
					return 'bg-green-400 hover:bg-green-500 text-white text-center'
				case GLOBAL_MONITORING_VALUES.DOING:
					return 'bg-yellow-400  hover:bg-yellow-600 text-center'
				case GLOBAL_MONITORING_VALUES.NOT_NEEDED:
					return 'bg-gray-400 hover:bg-gray-500 text-white text-center'
				default:
					return 'hover:bg-blue-200 text-center'
			}
		},
	}

	/**
	 * A l'initialisation de la grille, nous définissons nos variables globales.
	 * @param api
	 * @param columnApi
	 */
	function prepareGrid({ api, columnApi }) {
		api.closeToolPanel()

		columnApi.applyColumnState({
			state: [
				{
					colId: 'estab',
					sort: 'asc',
				},
			],
			defaultState: { sort: null },
		})

		setGrid({ columnApi, api })

		fetchData(columnApi, api)()
	}

	/**
	 * Charge l'établissement sur lequel l'utilisateur a cliqué, et change la vue sur le suivi de cet établissement.
	 * @param data
	 * @param field
	 */
	function fetchSchoolWorkflowAndDisplayPreview({ data, colDef: { field } }) {
		if (field === 'estab') {
			setDisplayedSchool(null)
			const [schoolUai] = data.estab.split(' ')

			setDisplayedSchool({ uai: schoolUai, fullName: data.estab })
		}
	}

	function resetEstabs() {
		showModal('confirmAction', true, {
			content: (
				<>
					Êtes-vous sûr de vouloir remettre à zéro les établissements suivants: <strong>{selectedUAIs.join()}</strong>
				</>
			),
			action: async () => {
				setLoading(true)

				for (const uai of selectedUAIs) {
					try {
						await resetSchoolWorkflow(context, uai)
						addToast(`${uai} remis à zéro.`, { appearance: 'success' })
					} catch (e) {
						addToast(`${uai}: ${e.toString()}`, { appearance: 'error' })
					}
				}

				fetchData(grid.columnApi, grid.api)()
			},
		})
	}

	return (
		<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
			<Heading textColor={!isLightTheme && 'light'} className={'fade-in-right'}>
				{translate('global_monitoring.title')}
			</Heading>
			<Hr />

			{displayedSchool?.uai && (
				<Section className={`tab-container fade-in-fwd`} backgroundColor={isLightTheme ? '' : 'black'} style={{ borderColor: 'transparent' }}>
					<header className="flex justify-between">
						<Heading size={6}>{displayedSchool.fullName}</Heading>
						<Button color={isLightTheme ? 'light' : 'black'} type={'reset'} onClick={() => setDisplayedSchool(null)}>
							<Icon icon={'fa-2x fal fa-times-circle'} />
						</Button>
					</header>
					<SchoolMonitoring uai={displayedSchool.uai} />
				</Section>
			)}

			<section className={'buttons'} style={{ flexWrap: 'initial' }}>
				<Button color={'danger'} disabled={selectedUAIs.length === 0} onClick={resetEstabs}>
					<Icon icon={'fad fa-exclamation-triangle'} />
					<span className="text-icon">{translate('global_monitoring.reset')}</span>
				</Button>
			</section>

			{/* Filtres */}
			{workflowFilters && (
				<div className="field is-grouped is-grouped-multiline">
					<Form.Control>
						<Tag.Group className={'fade-in-right'}>
							<Tag
								remove
								renderAs="a"
								color={'danger'}
								onClick={() => {
									resetGridFilter('workflow')
								}}
							/>
						</Tag.Group>
					</Form.Control>

					{Object.keys(workflowFilters).map((filter, k) => (
						<Form.Control key={k}>
							<Tag.Group className="has-addons fade-in-right" style={{ animationDelay: `${k * 100}ms` }}>
								<Tag color={'info'}>{filter}</Tag>
								<Tag color={'dark'} remove renderAs="a" onClick={() => removeGridFilter('workflow', filter, null, 'GlobalMonitoring.removeTag')} />
							</Tag.Group>
						</Form.Control>
					))}
				</div>
			)}

			<div className={'agrid-container'}>
				<section className={`ag-theme-balham${isLightTheme ? '' : '-dark'} agrid`} style={{ height: '95%' }}>
					{loading ? (
						<p>Chargement...</p>
					) : (
						<AgGridReact
							localeText={lang === 'fr' ? AG_GRID_LOCALE_FR : AG_GRID_LOCALE_EN}
							statusBar={{
								statusPanels: [
									{
										statusPanel: 'agTotalAndFilteredRowCountComponent',
										align: 'left',
									},
									{
										statusPanel: 'agFilteredRowCountComponent',
										align: 'left',
									},
									{ statusPanel: 'agSelectedRowCountComponent', align: 'left' },
								],
							}}
							defaultColDef={defaultColumnsDefs}
							columnDefs={columns}
							rowData={data}
							rowSelection="multiple"
							groupSelectsChildren
							suppressFieldDotNotation
							suppressRowClickSelection
							suppressAggFuncInHeader
							popupParent={document.body}
							sideBar
							debounceVerticalScrollbar
							applyColumnDefOrder
							animateRows
							onGridReady={prepareGrid}
							onSelectionChanged={e => setSelectedUAIs(e.api.getSelectedRows().map(({ estab }) => estab.slice(0, 8)))}
							onCellClicked={fetchSchoolWorkflowAndDisplayPreview}
							onFilterChanged={() => {
								const gridFilter = grid.api.getFilterModel()

								setGridFilter('workflow', gridFilter)
							}}
						/>
					)}
				</section>
			</div>
		</Section>
	)
}

export default GlobalMonitoring
