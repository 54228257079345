import React from 'react'
import Table from '../Table'
import Settings from '../Settings'
import Dashboard from '../Dashboard'
import GlobalMonitoring from '../GlobalMonitoring'
import Overview from '../Overview'
import SchoolMonitoring from '../SchoolMonitoring'
import Import from '../Import'
import GlobalContext from '../../contexts/GlobalContext'
import DocumentManager from '../DocumentManager'
import GlobalSettings from '../GlobalSettings'

/**
 * Routeur pour afficher la vue sélectionné.
 */
export default class Router extends React.Component {
	static contextType = GlobalContext

	/**
	 * Récupère la vue selon notre contexte
	 *
	 * Liste des vues:
	 * 	- import = Envoie d'un fichier CSV pour import en masse
	 * 	- changelog = Liste des mise à jour
	 * 	- overview = vue globale Tabuleo
	 * 	- global_monitoring = Suivi global des établissements.
	 * 	- users = Grille avec la liste des utilisateurs.
	 * 	- dashboard = Graphique de résumé d'un établissement
	 * 	- params = Paramètres établissements
	 * @returns {JSX.Element}
	 */
	render() {
		const { view } = this.context

		switch (view) {
			case 'import':
				return <Import />

			case 'overview':
				return <Overview />
			case 'global_monitoring':
				return <GlobalMonitoring />
			case 'config':
				return <GlobalSettings />
			case 'document_manager':
				return <DocumentManager />

			case 'users':
				return <Table />
			case 'dashboard':
				return <Dashboard />
			case 'monitoring':
				return <SchoolMonitoring />
			case 'params':
				return <Settings />

			default:
				return <div />
		}
	}
}
