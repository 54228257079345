import React, { Fragment } from 'react'
import { Box, Button, Form, Heading, Notification, Section } from 'react-bulma-components'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import Icon from './utils/Icon'
import Queries from '../tools/Query'
import FileUtils from './utils/FileUtils'

const initialState = {
	filename: undefined,
	loading: false,
	importNumber: undefined,
	error: undefined,
	isOk: false,
	users: undefined,
	files: undefined,
	uai: undefined,
	importMaj: false,
	importTeaching: false,
}

export default class Import extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = initialState
		this.fileInput = React.createRef()
	}

	handleChange = event => {
		let value = this.fileInput.current?.value.split('\\')
		this.setState({
			filename: value[value.length - 1],
		})
	}

	handleSubmit = event => {
		event.preventDefault()

		const formData = new FormData()
		formData.append('csvfile', this.fileInput.current.files[0])

		this.setState({ loading: true })

		Queries.checkImportFile(this.context, formData, this.state.importMaj, this.state.importTeaching)
			.then(res => {
				// console.log({ app: this.getAppsForPreview(res.users), tea: res.users, nbTeaching: Math.max(...res.users.map(({ teaching }) => teaching.length)) })
				this.setState({
					error: undefined,
					apps: this.getAppsForPreview(res.users),
					nbTeaching: Math.max(...res.users.map((user) => Array.isArray(user.teaching) ? user.teaching.length : 0)),
					maxOptions: this.getMaxOptions(res.users),
					loading: false,
					isOk: true,
					importNumber: res.importNumber,
					uai: res.users[0].informations.uai,
					users: res.users,
				})
			})
			.catch(err => {
				let errors = err
				if (Array.isArray(err)) {
					errors = {}
					err.forEach((er, idx) => (errors['erreur' + idx] = er.msg))
				}

				this.fileInput.current.value = ''

				this.setState({
					error: errors,
					loading: false,
					loadingCancel: false,
					isOk: undefined,
					filename: undefined,
				})
			})
	}

	getMaxOptions = users => {
		return Math.max(...users.map(({ options }) => Object.keys(options).length))
	}

	getAppsForPreview = users => {
		const apps = []
		users.forEach(({ applications }) => applications.filter(({ idApplication }) => !apps.includes(idApplication)).map(({ idApplication }) => apps.push(idApplication)))
		return apps
	}

	confirmUpload = confirm => {
		const loadingParam = confirm ? 'loadingConfirm' : 'loadingCancel'

		this.setState({ [loadingParam]: true })

		const { importNumber, uai } = this.state
		const { translate, addToast, loadSchool, setGridFilter, grid } = this.context

		Queries.confirmImportFile(this.context, importNumber, confirm ? '1' : '0')
			.then(res => {
				this.setState({
					[loadingParam]: false,
					finalSuccess: true,
				})

				const filterOnImport = {
					'N° import': {
						values: [importNumber.toString()],
						filterType: 'set',
					},
				}

				loadSchool(uai).then(() => {
					setGridFilter('table', filterOnImport, null, 'Import.loadEstab')
				})
			})
			.catch(err => {
				this.setState({
					[loadingParam]: false,
					finalSuccess: undefined,
				})

				addToast(translate('global.error.occurred'), { appearance: 'error' })
			})
	}

	cancelImport() {
		const { addToast, translate } = this.context
		Queries.confirmImportFile(this.context, this.state.importNumber, '0')
		this.setState(initialState)
		addToast(translate('import.cancel_success'), { appearance: 'warning' })
	}

	render() {
		const { isLightTheme, translate } = this.context
		const { error, isOk, filename, importNumber, users, nbTeaching, apps, loadingConfirm, finalSuccess, maxOptions, importMaj, importTeaching, loading } = this.state

		const allOptions = []
		for (let i = 1; i <= maxOptions; i++) {
			allOptions.push(i)
		}

		return (
			<>
				<Section style={{ maxHeight: 'calc(100vh - 3.5rem)', overflowY: 'auto', overflowX: 'hidden' }}>
					<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
						{isOk ? translate('import.preview') : translate('import.title')}
					</Heading>
					<Hr />
					<Box className="fade-in-bottom" backgroundColor={!isLightTheme ? 'black' : ''} style={{ position: 'relative' }}>
						{error && (
							<Notification color={'danger'}>
								<div className="is-flex" style={{ justifyContent: 'space-between' }}>
									<article>
										<Icon key={error} icon={'fad fa-exclamation'} />
										<span className="is-text-icon">
											{translate('import.error_report')} : <br />
											{Object.values(error).map((reason, idx) => (
												<Fragment key={idx}>
													<span>- {reason}</span>
													<br />
												</Fragment>
											))}
										</span>
									</article>
									<Button color={'danger'} onClick={() => FileUtils.saveErrorReport(error)}>
										<span className="is-text-icon">{translate('global.download')}</span>
										<Icon icon={'far fa-arrow-to-bottom'} />
									</Button>
								</div>
							</Notification>
						)}
						{isOk && (
							<Notification color={'success'}>
								<Icon key={importNumber} icon={'fad fa-check-circle'} />
								<span className="is-text-icon">{translate('import.valid_file', { importNumber })}</span>
								<p>{translate('import.count', { count: users.length })}</p>
							</Notification>
						)}

						{finalSuccess ? (
							<p>{importTeaching ? translate('import.success_teaching') : translate('import.success_users')}</p>
						) : !isOk ? (
							<form onSubmit={this.handleSubmit}>
								<Heading size={5} className={'is-flex'} style={{ justifyContent: 'space-between', placeItems: 'end' }}>
									{translate('import.select_file')}
									<span style={{ marginLeft: '1rem' }} className="is-size-6 has-text-weight-light is-uppercase">
										<a href="https://confluence.external-share.com/content/72516/notice_d_import" rel="noopener noreferrer" target="_blank">
											{translate('import.instruction_for_use')}
										</a>
									</span>
								</Heading>
								<Hr />
								<Form.Field className={'has-addons'}>
									<Form.Control className={'is-expanded'}>
										<div className="file has-name is-fullwidth">
											<label className="file-label">
												<input required className="file-input" type="file" accept=".csv" ref={this.fileInput} onChange={this.handleChange} />
												<span className="file-cta">
													<Icon icon={'fad fa-file-import'} />
													<span className="file-label is-text-icon">{translate('global.import')}</span>
												</span>
												<span className="file-name">{filename || translate('import.no_file')}</span>
											</label>
										</div>
									</Form.Control>
									<Form.Control>
										<Button color={'primary'} type={'submit'} loading={loading} disabled={!filename}>
											<Icon icon={'fal fa-cloud-upload'} />
											<span className="is-text-icon">{isOk ? translate('global.import') : translate('import.verify')}</span>
										</Button>
									</Form.Control>
								</Form.Field>
								<br />
								<div className={'flex space-x-4'}>
									<p className="fade-in-bottom">
										{translate('settings.maj')}
										<fieldset style={{ marginTop: '0.5rem' }}>
											<input id="importMaj" type="checkbox" className="switch is-rounded is-success is-small" disabled={importTeaching} checked={importMaj} name={'importMaj'} onChange={() => this.setState({ importMaj: !importMaj })} />
											<label htmlFor="importMaj" />
										</fieldset>
									</p>
									<p className="fade-in-bottom" style={{ animationDelay: '125ms' }}>
										{translate('import.teaching')}
										<fieldset style={{ marginTop: '0.5rem' }}>
											<input id="importTeaching" type="checkbox" className="switch is-rounded is-success is-small" checked={importTeaching} name={'importTeaching'} onChange={() => this.setState({ importTeaching: !importTeaching })} />
											<label htmlFor="importTeaching" />
										</fieldset>
									</p>
								</div>
								<Heading size={6} subtitle>
									<Icon icon={'far fa-info-circle'} color={'info'} />
									<span className="is-text-icon">
										{translate('import.recommend_frame')}
										<a download="Trame.csv" target="_blank" rel="noopener noreferrer" href="https://assets-tabuleo.s3.eu-west-3.amazonaws.com/Trame.csv" style={{ marginLeft: '0.25rem', textDecoration: 'underline' }}>
											{translate('import.frame')},
										</a>
										<a download="Trame.csv" target="_blank" rel="noopener noreferrer" href="https://assets-tabuleo.s3.eu-west-3.amazonaws.com/Trame_enseignements.csv" style={{ marginLeft: '0.25rem', textDecoration: 'underline' }}>
											{translate('import.frame_teaching')}
										</a>
									</span>
								</Heading>
							</form>
						) : (
							<div className="fade-in-bottom">
								<div className="table-container" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
									<table className={`table is-bordered is-striped is-narrow ${!isLightTheme ? 'is-dark' : ''}`}>
										<thead className="has-text-centered" style={{ borderBottom: '5px double #dbdbdb' }}>
											<tr className="has-text-weight-light is-uppercase" style={{ letterSpacing: '2px' }}>
												<td colSpan={importTeaching ? 3 : 9}>Informations</td>
												{!importTeaching && (
													<td style={{ verticalAlign: 'inherit' }} colSpan={maxOptions}>
														Options
													</td>
												)}
												{!importTeaching && apps && <td colSpan={apps.length * 2}>Applications</td>}
												{importTeaching && <td colSpan={nbTeaching * 2}>Enseignements</td>}
											</tr>
											<tr>
												{!importTeaching && <th>{translate('global.uai')}</th>}
												{!importTeaching && <th>{translate('global.profile')}</th>}
												<th>{translate('global.lastname')}</th>
												<th>{translate('global.firstname')}</th>
												<th>{translate('global.login')}</th>
												{!importTeaching && <th>{translate('global.password')}</th>}
												{!importTeaching && <th>{translate('global.level')}</th>}
												{!importTeaching && <th>{translate('global.class')}</th>}
												{!importTeaching && <th>{translate('global.admin')}</th>}
												{!importTeaching && (allOptions.length > 0 ? allOptions.map(i => <td>{i}</td>) : <td></td>)}
												{!importTeaching &&
													apps?.map(app => (
														<>
															<th>Etat&nbsp;-&nbsp;{app}</th>
															<th>{app}</th>
														</>
													))}
												{importTeaching &&
													[...Array.from({ length: nbTeaching })]?.map((_, index) => (
														<>
															<th colSpan={2}>Enseignement {index + 1}</th>
														</>
													))}
											</tr>
										</thead>
										<tbody>
											{users?.map(({ informations, options, applications, teaching }) => (
												<tr>
													{!importTeaching && <td>{informations.uai}</td>}
													{!importTeaching && <td>{informations.profile}</td>}
													<td>{informations.lastName}</td>
													<td>{informations.firstName}</td>
													<td>{informations.login}</td>
													{!importTeaching && <td>{informations.password}</td>}
													{!importTeaching && <td>{informations.level}</td>}
													{!importTeaching && <td>{informations.class}</td>}
													{!importTeaching && <td>{informations.adminMode}</td>}
													{!importTeaching && (allOptions.length > 0 ? allOptions.map(optionIdx => <td>{(options && options[optionIdx - 1]?.idOption) || ''}</td>) : <td></td>)}
													{!importTeaching &&
														(applications ? (
															apps.map(appName => {
																const application = applications.find(({ idApplication }) => idApplication === appName)
																return (
																	<>
																		<td>
																			<span>{application ? application.etat : 0}</span>
																		</td>
																		<td>
																			{application && (
																				<span>
																					<strong>{application?.login}</strong> {application?.code && `[${application.code}]`}
																				</span>
																			)}
																		</td>
																	</>
																)
															})
														) : (
															<td colSpan={apps.length} />
														))}
													{importTeaching &&
														teaching.map(({ class: classe, discipline }) => {
															return (
																<>
																	<td>
																		<span>{classe}</span>
																	</td>
																	<td>
																		<span>{discipline}</span>
																	</td>
																</>
															)
														})}
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="buttons">
									<Button color={'danger'} onClick={() => this.cancelImport()}>
										{translate('global.cancel')}
									</Button>
									<Button color={'success'} loading={loadingConfirm} onClick={() => this.confirmUpload(true)}>
										<Icon icon="fal fa-check-double" />
										<span className="is-text-icon">{translate('global.confirm')}</span>
									</Button>
								</div>
							</div>
						)}
					</Box>
				</Section>
			</>
		)
	}
}
