import React, { useCallback, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import Query from '../../tools/Query'
import { Formik } from 'formik'
import { Button, Columns, Form, Heading, Image, Section } from 'react-bulma-components'
import Icon from '../utils/Icon'
import Hr from '../utils/Hr'
import * as yup from 'yup'
import { escape } from 'lodash'

function UnitaryAdd() {
	const context = useContext(GlobalContext)
	const {
		school,
		school: {
			informations: { uai, name }
		},
		translate,
		addToast,
		grid,
		showModal,
		toggleUserAdd,
		refresh,
		setGridFilter,
		isLightTheme,
		shouldMount,
		validations: { conversions, regexes, user }
	} = context

	const [loading, setLoading] = useState({
		profiles: true,
		final: false,
		password: false
	})
	const [profiles, setProfiles] = useState([])
	const [levels, setLevels] = useState(getAllLevels('Eleve'))
	const [classes, setClasses] = useState([])
	const [options, setOptions] = useState([])

	const [filterOptions, setFilterOptions] = useState('')


	const [finalValues, setFinalValues] = useState({
		options: [],
		apps: ['Educadhoc', 'Biblio-Manuels', 'Lib Manuels']
	})

	/**
	 * Ajout d'un listener qui ferme l'ajout unitaire sur l'utilisateur appuie sur la touche ECHAP.
	 */
	useEffect(() => {
		function closeOnEscapeKey(event) {
			if (event.code === 'Escape') {
				toggleUserAdd()
			}
		}

		window.addEventListener('keyup', closeOnEscapeKey)

		return () => {
			window.removeEventListener('keyup', closeOnEscapeKey)
		}
	}, [toggleUserAdd])

	/**
	 * Génère un mot de passe aléatoire et le saisie dans le champs.
	 * @param setFieldValue
	 */
	function generatePassword(setFieldValue) {
		setLoading(loading => ({
			...loading,
			password: true
		}))
		Query.getRandomPasswords(context, 1).then(password => {
			setFieldValue('password', password[0])
			setLoading(loading => ({
				...loading,
				password: false
			}))
		})
	}

	function applyConversion(field, value) {
		switch (conversions[field]) {
			case 'MAJ':
				return value.toUpperCase()
			case 'MIN':
				return value.toLowerCase()
			case 'FIRSTMAJ':
				return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
			default:
				return value
		}
	}

	/**
	 * Modifie les champs pour les ajuster aux règles générales.
	 * @param target
	 * @param values
	 */
	function applyBlur({ target }, values) {
		const {
			informations: { extension },
			regexEstab,
			regexGlobal
		} = school
		// On prend le 2 ème caractère car le premier est un backslash
		const separator = regexEstab ? regexEstab.find(reg => reg.field === 'login').regex[0] : regexGlobal.find(reg => reg.field === 'login').special[0]
		const value = applyConversion(target.name, target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))

		const compliantValue = value.replace(regexes[target.name], '')
		values[target.name] = compliantValue

		if (target.name === 'firstName' || target.name === 'lastName') {
			values.login = applyConversion('login', [values.firstName, values.lastName, extension].join(separator))
		}
		// On notifie l'utilisateur si il y a un changement

		if (compliantValue.toLowerCase() !== target.value.toLowerCase()) {
			addToast(translate('user_add.modified_field', { field: translate('global.' + target.name.toLowerCase()) }), { appearance: 'warning' })
		}
	}

	/**
	 * Récupère tous les niveaux selon le nouveau profil choisi.
	 * @param newProfile
	 */
	function getAllLevels(newProfile) {
		const levels = []
		grid.forEachNode(({ data: { Désactivé, Profil, Niveau } }) => {
			if (Profil === newProfile && Désactivé === 0) {
				levels.push(Niveau)
			}
		})
		return [...new Set(levels)]
	}

	/**
	 * Met à jour l'ensemble des champs lors du changement d'un niveau.
	 * @param newProfile
	 * @returns {string}
	 */
	function changeLevel(newProfile) {
		const levels = getAllLevels(newProfile)
		setLevels(levels)

		const firstLevel = levels[0]

		updateClasses(firstLevel)
		updateOptions(firstLevel, null)
		updateApps(firstLevel, null)

		return firstLevel
	}

	/**
	 * Trouve les classes correspondantes au nouveau niveau sélectionné.
	 * @param newLevel
	 */
	const updateClasses = useCallback(
		newLevel => {
			const classes = []

			grid.forEachNode(({ data: { Désactivé, Niveau, Classe } }) => {
				if (Niveau === newLevel && Désactivé === 0) {
					Classe && classes.push(Classe)
				}
			})

			setClasses([...new Set(classes)])
		},
		[grid]
	)

	/**
	 * Trouve les options correspondante au nouveau niveau et même profil profil.
	 * @param newLevel
	 * @param newProfile
	 */
	const updateOptions = useCallback(
		(newLevel, newProfile) => {
			const options = []

			grid.forEachNode(({ data }) => {
				const sameLevelOrSameProfile = data.Niveau === newLevel || (newProfile && newProfile === data.Profil)

				if (data.Désactivé === 0 && sameLevelOrSameProfile) {
					for (const column in data) {
						const value = data[column]
						const isNotEmptyOptionColumn = /Option[0-9]*/.test(column) && value !== null

						isNotEmptyOptionColumn && options.push(value)
					}
				}
			})

			setOptions([...new Set(options)])
		},
		[grid]
	)

	/**
	 * Trouve les apps correspondante au nouveau niveau et/ou classe.
	 * @param newLevel
	 * @param newClass
	 */
	const updateApps = useCallback(
		(newLevel, newClass) => {
			let apps = {}

			Object.values(school.applications).forEach(app => {
				apps[app.name] = 1
			})

			const isClassUpdated = newClass && newClass !== 'Aucune'
			const isLevelUpdated = newLevel !== null

			function enableAppsThatUsersHave(node) {
				for (const column in node.data) {
					if (Object.keys(apps).includes(column) && !node.data[column] && String(node.data[column]) !== '1') {
						// si un eleve n'a pas l'app on supprime
						// l'application de l'objet principal
						delete apps[column]
					}
				}
			}

			switch (true) {
				case isClassUpdated:
					let someoneAlreadyHaveThisClass = false
					grid.forEachNode(node => {
						if (node.data.Désactivé === 0 && newClass === node.data.Classe) {
							enableAppsThatUsersHave(node)
						}
					})

					if (!someoneAlreadyHaveThisClass) {
						apps = {}
					}
					break

				case isLevelUpdated:
					let someoneAlreadyHaveThisLevel = false
					grid.forEachNode(node => {
						if (node.data.Désactivé === 0 && newLevel === node.data.Niveau) {
							enableAppsThatUsersHave(node)
							someoneAlreadyHaveThisLevel = true
						}
					})

					if (!someoneAlreadyHaveThisLevel) {
						apps = {}
					}
					break

				default:
					for (const app of Object.keys(apps)) {
						delete apps[app]
					}
					break
			}

			finalValues.apps = Object.keys(apps)
			setFinalValues({
				...finalValues
			})
		},
		[finalValues, grid, school.applications]
	)

	/**
	 * Récupère les différents profils
	 */
	useEffect(() => {
		if (profiles.length === 0) {
			Query.getUsersProfile(context).then(profiles => {
				setProfiles(profiles)
				updateClasses(levels[0])
				updateOptions(levels[0])
				updateApps(levels[0], null)
				setLoading(loading => ({
					...loading,
					profiles: false
				}))
			})
		}
	}, [context, levels, profiles.length, updateApps, updateClasses, updateOptions])

	/**
	 * Ajoute ou supprime une option/application cochée.
	 * @param name
	 * @param checked
	 * @param type
	 */
	function handleSwitch({ target: { name, checked } }, type) {
		const { options, apps } = finalValues

		checked ? (type === 'option' ? options.push(name) : apps.push(name)) : type === 'option' ? options.splice(options.indexOf(name), 1) : apps.splice(apps.indexOf(name), 1)

		setFinalValues({
			...finalValues
		})
	}

	/**
	 * Affiche la modal pour ajouter une nouvelle valeur (classe, niveau, option).
	 * @param type
	 * @param values
	 */
	function modalAddValue(type, values) {
		showModal('unitaryAdd', true, {
			type,
			changeType: newValue => {
				switch (type) {
					case 'class':
						values.class = newValue
						setClasses(classes => {
							classes.push(newValue)

							return [...new Set(classes)]
						})
						break

					case 'level':
						values.level = newValue
						setLevels(levels => {
							levels.push(newValue)

							return [...new Set(levels)]
						})
						setClasses([])

						updateOptions(newValue, values.profile)
						updateApps(null, null)
						break

					case 'options':
						setOptions(options => {
							options.push(newValue)

							return [...new Set(options)]
						})
						setFinalValues(values => {
							values.options.push(newValue)

							return { ...values }
						})
						break

					default:
						return
				}
			}
		})
	}

	function handleSubmit(values) {
		setLoading({
			final: true,
			password: false,
			profiles: false
		})

		const { apps, options } = finalValues

		//escape special character like '
		values.comment = escape(values.comment)

		if (apps.length > 0) {
			return Query.getRandomPasswords(context, apps.length).then(passwords => {
				showModal('unitaryAddApps', true, {
					apps,
					options,
					values,
					passwords,
					cancel: () => setLoading({ final: false, password: false, profiles: false })
				})
			})
		}
		Query.addUser(context, { values: { ...values, adminMode: 0 }, options, applications: apps })
			.then(() => {
				addToast(translate('modals.specific.add_user.created'), { appearance: 'success' })
				toggleUserAdd()
				refresh()
				setGridFilter(
					'table',
					{
						Login: {
							filterType: 'text',
							type: 'equal',
							filter: values.login
						}
					},
					null,
					'UnitaryAdd.addUser'
				)
				setLoading({
					profiles: false,
					final: false,
					password: false
				})
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				setLoading({
					profiles: false,
					final: false,
					password: false
				})
			})
	}

	return (
		<Section className={`tab-container fade-in-fwd`} backgroundColor={isLightTheme ? '' : 'black'}
				 style={{ borderColor: 'transparent' }}>
			<Formik
				validationSchema={yup.object(user)}
				initialValues={{
					uai,
					firstName: undefined,
					lastName: undefined,
					login: undefined,
					profile: 'Eleve',
					level: levels[0],
					class: null,
					tabletNumber: undefined,
					comment: undefined,
					email: ''
				}}
				onSubmit={values => handleSubmit(values)}
			>
				{({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<header className={'is-flex'} style={{ justifyContent: 'space-between' }}>
							<article>
								<Heading textColor={isLightTheme ? '' : 'light'}>
									{translate('user_add.title')}{' '}
									<span className="subtitle">
										{translate('user_add.for_school')} <strong
										className={isLightTheme ? '' : 'has-text-grey-lighter'}>{name}</strong>
									</span>
								</Heading>
							</article>
							{shouldMount('Table', 'Lecture') && (
								<Button color={isLightTheme ? 'light' : 'black'} type={'reset'}
										onClick={() => toggleUserAdd()}>
									<Icon icon={'fa-2x fal fa-times-circle'} />
								</Button>
							)}
						</header>
						<Hr />
						<Columns multiline>
							<Columns.Column>
								<Heading size={6} textColor={isLightTheme ? '' : 'grey'}>
									{translate('user_add.user_data')}
								</Heading>
								<Form.Field className="has-addons">
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft size={'small'} className={'is-expanded'}>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} readOnly size={'small'}
													name={'uai'} value={uai} isValid={uai}
													className={errors.UAI && 'is-danger'} />
										<Icon icon={'fad fa-university'} className={'is-small is-left'} />
										<Form.Help type={'invalid'} color={'danger'}>
											{errors.UAI}
										</Form.Help>
									</Form.Control>
								</Form.Field>
								<Form.Field className="has-addons">
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft size={'small'} className="is-expanded">
										<Form.Input
											backgroundColor={isLightTheme ? '' : 'dark'}
											placeholder={translate('global.firstname')}
											size={'small'}
											name={'firstName'}
											onChange={handleChange}
											onBlur={event => {
												applyBlur(event, values)
												handleBlur(event)
											}}
											value={values.firstName}
											isInvalid={(touched.firstName || values.firstName) && errors.firstName}
											isValid={values.firstName}
											className={errors.firstName && 'is-danger'}
										/>
										<Icon icon={'fad fa-id-card'} className={'is-small is-left'} />
										<Form.Help type={'invalid'} color={'danger'}>
											{errors.firstName}
										</Form.Help>
									</Form.Control>
								</Form.Field>
								<Form.Field className="has-addons">
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft size={'small'} className={'is-expanded'}>
										<Form.Input
											backgroundColor={isLightTheme ? '' : 'dark'}
											placeholder={translate('global.lastname')}
											size={'small'}
											name={'lastName'}
											onChange={handleChange}
											onBlur={event => {
												applyBlur(event, values)
												handleBlur(event)
											}}
											value={values.lastName}
											isInvalid={(touched.lastName || values.lastName) && errors.lastName}
											isValid={values.lastName}
											className={errors.lastName && 'is-danger'}
										/>
										<Icon icon={'fad fa-id-card'} className={'is-small is-left'} />
										<Form.Help type={'invalid'} color={'danger'}>
											{errors.lastName}
										</Form.Help>
									</Form.Control>
								</Form.Field>
								<Form.Field className="has-addons">
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft size={'small'} className="is-expanded">
										<Form.Input
											backgroundColor={isLightTheme ? '' : 'dark'}
											placeholder={translate('global.username')}
											size={'small'}
											name={'login'}
											onChange={handleChange}
											onBlur={event => {
												applyBlur(event, values)
												handleBlur(event)
											}}
											value={values.login}
											isInvalid={(touched.login || values.login) && errors.login}
											isValid={values.login}
											className={errors.login && 'is-danger'}
										/>
										<Icon icon={'fad fa-at'} className={'is-small is-left'} />
										<Form.Help type={'invalid'} color={'danger'}>
											{errors.login}
										</Form.Help>
									</Form.Control>
								</Form.Field>
								<Form.Field className={'has-addons'}>
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft className={'is-expanded'} size={'small'}>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} fullwidth
													placeholder={translate('global.password')} size={'small'}
													name={'password'} onChange={handleChange} value={values.password}
													className={errors.password && 'is-danger'} />
										<Icon icon={'fad fa-fingerprint'} className={'is-small is-left'} />
										<Form.Help type={'invalid'} color={'danger'}>
											{errors.password}
										</Form.Help>
									</Form.Control>
									<Form.Control size={'small'}>
										<Button type={'button'} color={'primary'} size={'small'}
												onClick={() => generatePassword(setFieldValue)}
												loading={loading.password}>
											Générer
										</Button>
									</Form.Control>
								</Form.Field>
								<Form.Field className="has-addons">
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft className={'is-expanded'} size={'small'}>
										<div
											className={`${isLightTheme ? '' : 'has-background-dark'} select is-small is-fullwidth ${loading.profiles ? 'is-loading' : ''} ${errors.profile ? 'is-danger' : ''}`}>
											<select
												name="profile"
												className={isLightTheme ? '' : 'has-background-dark'}
												value={values.profile}
												onChange={event => {
													values.class = null
													values.level = changeLevel(event.target.value)
													handleChange(event)
												}}
											>
												{loading.profiles ? (
													<option disabled>{translate('user_add.fetching_roles')}</option>
												) : (
													profiles.map((profile, key) => (
														<option key={key} value={profile.Nom}>
															{profile.Nom}
														</option>
													))
												)}
											</select>
										</div>
										<Icon icon={'fad fa-user-graduate'} className={'is-small is-left'} />
									</Form.Control>
								</Form.Field>
								<Form.Field className={'has-addons'} style={{ flexWrap: 'wrap' }}>
									<Form.Control>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} textColor={'danger'}
													style={{ width: '1.5rem' }} size={'small'} value={'*'} disabled />
									</Form.Control>
									<Form.Control iconLeft className={'is-expanded has-tooltip-arrow has-tooltip-top'}
												  data-tooltip={translate('global.level')} size={'small'}>
										<div
											className={`select is-small is-fullwidth ${errors.level ? 'is-danger' : ''}`}>
											<select
												name="level"
												className={isLightTheme ? '' : 'has-background-dark'}
												defaultValue={values.level}
												value={values.level}
												onChange={event => {
													const { value } = event.target
													values.class = null

													updateClasses(value)
													updateOptions(value, null)
													updateApps(value, null)
													handleChange(event)
												}}
											>
												<option value="" disabled>
													&lt;Niveau&gt;
												</option>
												{levels.map((level, idx) => (
													<option selected={level === values.level} value={level} key={idx}>
														{level}
													</option>
												))}
											</select>
										</div>
										<Icon icon={'fad fa-users-class'} className={'is-small is-left'} />
									</Form.Control>
									<Form.Control size={'small'}>
										<Button type={'button'} color={'primary'} size={'small'}
												onClick={() => modalAddValue('level', values)}>
											Ajouter
										</Button>
									</Form.Control>
									<Form.Help style={{ width: '100%' }} type={'invalid'} color={'danger'}>
										{errors.level}
									</Form.Help>
								</Form.Field>
								<Form.Field className={'has-addons'}>
									<Form.Control iconLeft className={'is-expanded has-tooltip-arrow has-tooltip-top'}
												  data-tooltip={translate('global.class')} size={'small'}>
										<div
											className={`select is-small is-fullwidth ${errors.class ? 'is-danger' : ''}`}>
											<select
												className={isLightTheme ? '' : 'has-background-dark'}
												name="class"
												value={values.class}
												onChange={event => {
													handleChange(event)
												}}
											>
												<option value="PLACEHOLDER" disabled>
													&lt;Classe&gt;
												</option>
												{classes.map((classe, idx) => (
													<option selected={classe === values.classe} value={classe}
															key={idx}>
														{classe}
													</option>
												))}
												<option value="">&lt;Vide&gt;</option>
											</select>
										</div>
										<Icon icon={'fad fa-chalkboard-teacher'} className={'is-small is-left'} />
									</Form.Control>
									<Form.Control size={'small'}>
										<Button type={'button'} color={'primary'} size={'small'}
												onClick={() => modalAddValue('class', values)}>
											Ajouter
										</Button>
									</Form.Control>
								</Form.Field>
								<Form.Field>
									<Form.Control iconLeft size={'small'}>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'}
													placeholder={translate('global.tablet_id')} size={'small'}
													name={'tabletNumber'} onChange={handleChange}
													value={values.tabletNumber} maxLength={12} />
										<Icon icon={'fad fa-tablet'} className={'is-small is-left'} />
									</Form.Control>
									<Form.Help style={{ width: '100%' }} type={'invalid'} color={'danger'}>
										{errors.tabletNumber}
									</Form.Help>
								</Form.Field>
								<Form.Field>
									<Form.Control iconLeft size={'small'}>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'}
													placeholder={translate('global.comment')} size={'small'}
													name={'comment'} onChange={handleChange} value={values.comment} />
										<Icon icon={'fad fa-comment-lines'} className={'is-small is-left'} />
									</Form.Control>
								</Form.Field>
								<Form.Field>
									<Form.Control iconLeft size={'small'}>
										<Form.Input backgroundColor={isLightTheme ? '' : 'dark'}
													placeholder={translate('global.email')} size={'small'}
													name={'email'} onChange={handleChange} value={values.email}
													type="email" />
										<Icon icon={'fad fa-mailbox'} className={'is-small is-left'} />
									</Form.Control>
								</Form.Field>
								<Form.Help style={{ width: '100%' }} type={'invalid'} color={'danger'}>
									{errors.email}
								</Form.Help>
							</Columns.Column>
							<Columns.Column>
								<section className="h-[37rem] relative flex flex-col transition-all">
									<article className="max-h-[50%] flex-initial flex flex-col transition-all">
										<Heading size={6} textColor={isLightTheme ? '' : 'grey'}>
											{translate('global.options')}
										</Heading>
										<Form.Field>
											<Form.Control iconLeft size={'small'}>
												<Form.Input backgroundColor={isLightTheme ? '' : 'dark'}
															placeholder={translate('global.search') + ' ' + translate('global.options')} size={'small'}
															name={'search-opt'} onChange={(e) => setFilterOptions(e.target.value)} value={filterOptions}
															type="text" />
												<Icon icon={'fal fa-search'} className={'is-small is-left'} />
											</Form.Control>
										</Form.Field>
										<div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 overflow-y-auto overflow-x-hidden">
											{options.sort().sort((a,b) => !a.toLowerCase().includes(filterOptions.toLowerCase())).map((option, key) => (
												<div key={key} className={`flex items-center ${!option.toLowerCase().includes(filterOptions.toLowerCase()) ? 'opacity-20' : ''}`}>
													<input checked={finalValues.options.includes(option)}
														   id={`opt_${key}`} type={'checkbox'} name={option}
														   className={'switch is-rounded is-success is-small'}
														   onChange={event => handleSwitch(event, 'option')} />
													<label htmlFor={`opt_${key}`}>{option}</label>
												</div>
											))}
										</div>
									</article>
									<article className="flex-1 h-1/2 flex flex-col pt-4 border-y transition-all">
										<Heading size={6} textColor={isLightTheme ? '' : 'grey'}>
											{translate('global.applications')}
										</Heading>
										<div
											className="grid md:grid-cols-2 gap-2 overflow-y-auto overflow-x-hidden">
											{school.applications
												.sort((a, b) => a.order - b.order)
												.map(({ name, urls: { picturePath }, idApplication }, key) => (
													<div key={key} className="flex items-center">
														<Image src={picturePath} size={32}
															   style={{ marginRight: '0.75rem' }} />
														<input checked={finalValues.apps.includes(name)}
															   id={idApplication} type={'checkbox'} name={name}
															   className={'switch is-rounded is-success is-small'}
															   onChange={event => handleSwitch(event, 'app')} />
														<label htmlFor={idApplication}>{name}</label>
													</div>
												))}
										</div>
									</article>
								</section>
							</Columns.Column>
						</Columns>
						<div className="buttons" style={{ justifyContent: 'flex-end' }}>
							<Button loading={loading.final} color={'success'} type={'submit'}>
								{translate('global.create')}
							</Button>
						</div>
					</form>
				)}
			</Formik>
		</Section>
	)
}

export default UnitaryAdd
