const MonitoringUtils = {
	/**
	 * Différents états pour une étape.
	 */
	STATES: {
		DONE: 1,
		DOING: 0,
		NONE: 2,
		NOT_NEEDED: 5,
	},

	/**
	 * Indique si une étape est complète.
	 * @param {STATES} state
	 * @return {boolean}
	 */
	isDone: state => state === MonitoringUtils.STATES.DONE,

	/**
	 * Indique si une étape est partiellement complète.
	 * @param {STATES} state
	 * @return {boolean}
	 */
	isDoing: state => state === MonitoringUtils.STATES.DOING,

	/**
	 * Indique si une étape n'a pas été commencé.
	 * @param {STATES} state
	 * @return {boolean}
	 */
	isNotStarted: state => state === MonitoringUtils.STATES.NONE,

	/**
	 * Indique si une étape n'est pas nécessaire à être faite.
	 * @param {STATES} state
	 * @return {boolean}
	 */
	isNotNeeded: state => state === MonitoringUtils.STATES.NOT_NEEDED,

	/**
	 * Renvoie la couleur associé à l'état :
	 *  - vert si fini
	 *  - jaune si en cours
	 *  - couleur par défaut si non commencé
	 *  - gris foncé si non nécessaire
	 * @param {STATES} state
	 * @param {boolean} completeClass Renvoie la classe CSS entière.
	 * @return {string}
	 */
	getStateColor: (state, completeClass = false) => {
		const { DONE, DOING, NONE, NOT_NEEDED } = MonitoringUtils.STATES

		const prefix = completeClass ? 'is-' : ''

		switch (state) {
			case DONE:
				return prefix + 'success'

			case DOING:
				return prefix + 'warning'

			case NOT_NEEDED:
				return prefix + 'dark'

			case NONE:
			default:
				return prefix + 'light'
		}
	},

	/**
	 * Renvoie un status uniformisé selon celui reçu par la BDD
	 * @param {string} state Etat de l'étape courant reçu de la bDD.
	 * @param {object} status Liste des états possibles reçu de la BDD
	 * @return {STATES}
	 */
	getStatusFromStatusList: (state, status) => {
		const { statusId } = status.find(({ statusLabel }) => statusLabel === state) ?? 2
		const { DONE, NOT_NEEDED, NONE } = MonitoringUtils.STATES

		switch (statusId) {
			case 1:
				return DONE
			case 5:
				return NOT_NEEDED
			case 2:
			default:
				return NONE
		}
	},
}

export default MonitoringUtils
