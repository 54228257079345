import React, { useContext, useState } from 'react';
import GlobalContext from '../../../contexts/GlobalContext';
import Icon from '../../utils/Icon';
// @ts-ignore
import { Button, Columns, Form, Heading, Image, Modal } from 'react-bulma-components'

/**
 * Champs similaire à celui de la navbar, pour filtrer parmi les établissements disponible.
 * @param addSchool
 * @return {JSX.Element}
 * @constructor
 */
export function Schools({ addSchool }: { addSchool: (uai: string) => void }) {
  const { translate, schools } = useContext(GlobalContext);

  const [value, setValue] = useState('');

  function filterEtab() {
    const escapedValue = (value || '').replace(/[()]/gi, '');
    const regex = new RegExp(escapedValue, 'gi');
    let filtered = (schools || []).filter((etab: any) => `${etab.Nom} ${etab.UAI} ${etab.Extension} ${etab.Commune}`.match(regex)) as any[];
    filtered.unshift('all')

    return filtered.length === 0 ? (
      <p className={'dropdown-item has-text-danger is-size-7'}>{translate('global.errors.school_not_found')}</p>
    ) : (
      filtered.map((school, key) => (
        <a
          href={'/'}
          key={key}
          onClick={event => {
            event.preventDefault();
            setValue('');
            addSchool(school === 'all' ? 'all' : school.UAI);
          }}
          className={'dropdown-item dropdown-school is-size-7 overflow-ellipsis'}
        >
            {school === 'all' ?
                <strong>Tous les établissements</strong> :
                <><strong>{school.UAI}</strong> - {school.Nom} - {school.Commune} {school.Extension && `(${school.Extension})`} - {school.Effectif}</>
            }
        </a>
      ))
    );
  }

  return (
    <>
      <Form.Field className='w-full'>
        <Form.Control className={'is-expanded'} iconLeft size={'small'}>
          <Form.Input autoComplete={'off'} size='small' fullwidth placeholder={translate('navbar.search_schools')}
                      value={value} onChange={(e: any) => setValue(e.target.value)} name={'school'} />
          <Icon icon={'fad fa-university'} className={'is-small is-left'} />
        </Form.Control>
      </Form.Field>
      <div className='max-h-64 overflow-y-auto overflow-x-hidden overflow-ellipsis'>{filterEtab()}</div>
    </>
  );
}
